/**
 * Orange Tunisia is a Youboox legacy login option, now hosted separately on a subdomain.
 *
 * Path to redirect: /{locale}/profile/providers/orange (keep any query params)
 * Production: https://tn.nextory.com/
 * Staging: https://tn.staging.nextory.app/
 */
export default function ({ i18n, query, redirect, route }) {
  const path = route.fullPath
  const orangeTunisiaPath = '/{locale}/profile/providers/orange'.replace(
    '{locale}',
    i18n.locale
  )
  const orangeTunisiaDomain =
    process.env.ENVIRONMENT === 'production'
      ? 'https://tn.nextory.com'
      : 'https://tn.staging.nextory.app'

  if (path.includes(orangeTunisiaPath)) {
    const url = new URL(`${orangeTunisiaDomain}${orangeTunisiaPath}`)
    Object.keys(query).forEach(key => url.searchParams.append(key, query[key]))

    return redirect(url.toString())
  }
}
