import { AxiosPromise } from 'axios'

import { Account } from '../api-types/Account'
import {
  AccountSubscription,
  ChangeSubscriptionResponse,
} from '../api-types/AccountSubscription'
import { Reason } from '../api-types/CancelReasons'
import { UpdateProfile } from '../api-types/Profile'
import { ReadingActivityFeed } from '../api-types/ReadingActivityFeed'
import { Profile as LegacyProfile } from '../legacy-api/types/profile'

import BaseApi from './baseApi'
import { modernAccountToLegacyProfile } from './converters/profileToLegacyProfile'
import convert from './helpers/convert'

export default class Me extends BaseApi {
  /**
   * Returns the current user information.
   *
   * Note: For Nextory's API, it'll be the Account.
   */
  getProfile(): AxiosPromise<LegacyProfile> {
    return convert(
      this.http({
        method: 'get',
        url: `/user/v1/me/account`,
      }),
      modernAccountToLegacyProfile
    )
  }

  getAccount(): AxiosPromise<Account> {
    return this.http({
      method: 'get',
      url: `/user/v1/me/account`,
    })
  }

  getReadingActivityFeed(): AxiosPromise<ReadingActivityFeed[]> {
    return this.http({
      method: 'get',
      url: '/user/v1/me/reading/activity/feed',
    })
  }

  updateProfile(data: { profile: Partial<UpdateProfile> }) {
    return this.http({
      method: 'patch',
      url: '/user/v1/me/account',
      data: data.profile,
    })
  }

  updatePassword(data: {
    current_password: string // The user's current password
    password: string // The new user's password
    password_confirmation: string // The new user's password confirmation (must match the password)
  }): AxiosPromise<void> {
    return convert(
      this.http({
        method: 'patch',
        url: `/user/v1/me/password`,
        data: {
          current_password: data.current_password,
          new_password: data.password,
        },
      })
    )
  }

  reactivateMeSubscription(): AxiosPromise<void> {
    return this.http({
      method: 'post',
      url: '/obgateway/v1/me/subscription/recover',
    })
  }

  /**
   * Let the user end his free trial and start paying, to lift time limit
   */
  payNow(): AxiosPromise<void> {
    return this.http({
      method: 'post',
      url: '/obgateway/v1/me/subscription/renew',
    })
  }

  /**
   * Unsubscribe the connected user
   */
  cancelSubscription(data: {
    comment: string
    reason: string // required
  }): AxiosPromise<void> {
    return this.http({
      method: 'post',
      url: '/obgateway/v1/me/subscription/cancel',
      data,
    })
  }

  /**
   * Get the cancel reasons
   */
  getCancelReasons(): AxiosPromise<Reason[]> {
    return this.http({
      method: 'get',
      url: '/order/v1/subscription/cancel/reasons',
    })
  }

  async getCancelReasonById(
    id: number | undefined
  ): Promise<Reason | undefined> {
    const reasons = (await this.getCancelReasons()).data

    if (!id) {
      // If no cancel reason is selected, return the last one ("Other")
      return reasons[reasons.length - 1]
    }

    return reasons.find(reason => reason.id === id)
  }

  /**
   * Returns information about the connected user active subscription
   */
  getProfileSubscription(): AxiosPromise<AccountSubscription> {
    return this.http({
      method: 'get',
      url: `/obgateway/v1/me/subscription`,
    })
  }

  /**
   * Update the  the connected user
   */
  updateSubscription(
    subscriptionId: number | string
  ): AxiosPromise<ChangeSubscriptionResponse> {
    subscriptionId = Number(subscriptionId)

    return this.http({
      method: 'POST',
      url: `/obgateway/v1/me/subscription/update`,
      params: {
        subscription_id: subscriptionId,
      },
    })
  }

  /**
   * Returns the user deep link (firebase)
   * @param redirectUrl
   * @returns {string} deep link URL
   */
  getUserDeeplink(redirectUrl: string): AxiosPromise<string> {
    let url = `/user/v1/me/login/token`

    if (redirectUrl) {
      url = `/user/v1/me/login/token?redirect=${redirectUrl}`
    }

    return this.http({
      method: 'get',
      url,
    })
  }

  getOffers(): AxiosPromise<{
    offer_end_date: string
    offer_id: number
    offer_name: string
    offer_slug: string
    offer_start_date: string
    offer_type:
      | 'REVERT_CANCEL_CAMPAIGN'
      | 'EXTENDED_OFFER_CAMPAIGN'
      | 'DOWNGRADE_SUBSCRIPTION'
  }> {
    return this.http({
      method: 'get',
      url: `/order/v1/me/offers`,
    })
  }

  applyOffer(offerId: number) {
    return this.http({
      method: 'post',
      url: `/order/v1/me/offers`,
      data: {
        offer_id: offerId,
      },
    })
  }
}
