import fetch from 'node-fetch'
import NodeCache from 'node-cache'
import { Subscription } from '~/plugins/hummingbird/api-types/Subscription'
import { NextoryContext } from '~/nx-types'

// Cache will only work in builds, not in dev mode
const cache = new NodeCache({ stdTTL: 60 * 5 /* minutes */ })

export type PaymentMethod =
  | 'amex'
  | 'bankid'
  | 'cartebancaire'
  | 'dimoco'
  | 'forbrugsforeningen'
  | 'ideal'
  | 'mc'
  | 'paypal'
  | 'sofort'
  | 'trustly'
  | 'visa'

export type LocalTelco = 'strex' | 'ice' | 'telenor' | 'telia'

interface Features {
  has_magazines: boolean
}

interface Dimoco {
  // locally users don't know what "Dimoco" is,
  // so we display local telcos names instead (strex for NO).
  alias: string
  local_telcos: LocalTelco[]
}

interface MarketProperty {
  available_payment_methods: {
    gateway: PaymentMethod
  }[]
  country: string
  dimoco_alias?: Dimoco
  features: Features
  free_trial_time_limit_hours: number | null
  trial_days: number
}

export default async function fetchWebConfig({
  $hummingbird,
  i18n,
}: NextoryContext) {
  const country = i18n.locale.substring(0, 2).toUpperCase()
  const cacheKey = `webConfig-${country}`
  let webConfig = cache.get(cacheKey) as {
    abtestUrls: string[]
    availablePaymentMethods: string[]
    bookCovers?: string[]
    dimoco?: Dimoco
    features: {
      hasMagazines: boolean
    }
    maximumPrice: {
      amount: number
      currency: string
    }
    minimumPrice: {
      amount: number
      currency: string
    }
    preselectedSubscription: string
    trialDays: number
    trialLimitedHours?: number
  }

  if (!webConfig) {
    // Nothing in cache, let's fetch web config
    // (formerly from Strapi, but it got wiped out for some unknown reason... https://cms.nextory.com/nx-5-web-config)
    let hasErrorOccurred = false
    const filename =
      process.env.ENVIRONMENT === 'production'
        ? 'production.json'
        : 'staging.json'
    // Note: Don't use ImgIx here (https://nextory-assets.imgix.net) because it doesn't allow us to update the file on-the-fly,
    // since it's cached for one year by it
    const response = await fetch(
      `https://storage.googleapis.com/nextory-frontend-assets/web/config/${filename}?t=${Date.now()}` // We add a timestamp to avoid caching, we already have our own efficient cache
    )
      .then(res => res.json())
      .then(config => {
        const unnecessaryProperties = ['id', 'created_at', 'updated_at']
        unnecessaryProperties.forEach(property => {
          delete config[property]
        })

        return config
      })
      .catch(async () => {
        // Consider this a hard-coded file fallback, in case of failure

        // Note: We need to inline the extension for an async JSON import
        // @ts-ignore
        // eslint-disable-next-line import/extensions
        const fallback = await import('./webconfig.json')
        return fallback.default
      })

    // abtest_urls is an array of URLs
    // We want to clean it and convert it to an array of strings, without the protocol, like ["/test", "/test2"]
    // -> It's used to conditionally load the AB test scripts (thus improving performance)
    let abtestUrls = (response.abtest_urls as string | string[]) || []

    if (!Array.isArray(abtestUrls)) {
      // Backward compatibility: it was a \n-separated string before, now it's an array
      abtestUrls = abtestUrls.split('\n')
    }

    const market = response.market_properties?.find(
      (market: MarketProperty) => market.country === country
    )

    /**
     * We need to fetch the subscriptions to get the min and max prices, trial days and currency
     */
    const subscriptions = await $hummingbird.deviceOpportunities
      .getDeviceOpportunities()
      .then(res => res.data?.subscriptions)
      .catch(() => {
        // To avoid caching an error, we set a flag
        hasErrorOccurred = true

        return [] as Subscription[]
      })
    const minimumSubscription = subscriptions[0] || { variants: [] }
    const minimumVariant = minimumSubscription.variants[0]
    const maximumSubscription = subscriptions[subscriptions.length - 1] || {
      variants: [],
    }
    const maximumVariant =
      maximumSubscription.variants[maximumSubscription.variants.length - 1]
    const currency = minimumSubscription?.currency || 'EUR'
    const preselectedSubscription =
      subscriptions
        .flatMap(subscription => subscription.variants)
        .find(variant => variant.is_preferred)?.alias || 'FAMILY'

    webConfig = {
      abtestUrls,
      availablePaymentMethods:
        market?.available_payment_methods?.map(
          (m: { gateway: PaymentMethod }) => m.gateway
        ) || [],
      bookCovers: market?.bookCovers || [],
      dimoco: market?.dimoco || undefined,
      features: {
        hasMagazines: market?.features?.has_magazines || false,
      },
      maximumPrice: {
        amount: parseFloat(maximumVariant?.amount || '0'),
        currency,
      },
      minimumPrice: {
        amount: parseFloat(minimumVariant?.amount || '0'),
        currency,
      },
      preselectedSubscription,
      trialDays: minimumSubscription.trial_days || 30,
      trialLimitedHours: market?.free_trial_time_limit_hours || undefined,
    }

    if (!hasErrorOccurred) {
      cache.set(cacheKey, webConfig)
    }
  }

  return webConfig
}
