
export default {
  props: {
    logosColor: {
      type: String,
      default: '#877b95', // default color for the footer
      validator: value => /^#[0-9A-F]{6}$/i.test(value),
    },

    showOnly: {
      type: String,
      default: 'all', // to display all the logos by default
      validator: value => ['all', 'card', 'bank', 'telco'].includes(value),
    },
  },

  /*
   * We have three types of payment methods:
   * - card:  for credit cards (only displayed in the ContentHero and NxOffers components)
   * - bank:  for banks
   * - telco: for mobile operators payment on bill
   */

  data() {
    return {
      logos: [
        {
          name: 'amex',
          alt: 'American Express',
          type: 'card',
          component: () => import('~/components/Nx/NxLogo/NxLogoAmex'),
        },
        {
          name: 'cartebancaire',
          alt: 'Carte Bancaire',
          type: 'card',
          component: () => import('~/components/Nx/NxLogo/NxLogoCartebancaire'),
        },
        {
          name: 'mc',
          alt: 'Mastercard',
          type: 'card',
          component: () => import('~/components/Nx/NxLogo/NxLogoMastercard'),
        },
        {
          name: 'visa',
          alt: 'Visa',
          type: 'card',
          component: () => import('~/components/Nx/NxLogo/NxLogoVisa'),
        },
        {
          name: 'forbrugsforeningen',
          alt: 'Forbrugsforeningen',
          type: 'card',
          component: () =>
            import('~/components/Nx/NxLogo/NxLogoForbrugsforeningen'),
        },
        {
          name: 'klarna',
          alt: 'Klarna',
          type: 'bank',
          component: () => import('~/components/Nx/NxLogo/NxLogoKlarna'),
        },
        {
          name: 'paypal',
          alt: 'Paypal',
          type: 'bank',
          component: () => import('~/components/Nx/NxLogo/NxLogoPaypal'),
        },
        {
          name: 'ideal',
          alt: 'iDEAL',
          type: 'bank',
          component: () => import('~/components/Nx/NxLogo/NxLogoIdeal'),
        },
        {
          name: 'sofort',
          alt: 'Sofort',
          type: 'bank',
          component: () => import('~/components/Nx/NxLogo/NxLogoSofort'),
        },
        {
          name: 'trustly',
          alt: 'Trustly',
          type: 'bank',
          component: () => import('~/components/Nx/NxLogo/NxLogoTrustly'),
        },
        {
          name: 'strex',
          alt: 'Strex',
          type: 'telco',
          component: () => import('~/components/Nx/NxLogo/NxLogoStrex'),
        },
        {
          name: 'vodafone',
          alt: 'Vodafone',
          type: 'telco',
          component: () => import('~/components/Nx/NxLogo/NxLogoVodafone'),
        },
        {
          name: 'o2',
          alt: 'O2',
          type: 'telco',
          component: () => import('~/components/Nx/NxLogo/NxLogoO2'),
        },
        {
          name: 't-mobile',
          alt: 'T-Mobile',
          type: 'telco',
          component: () => import('~/components/Nx/NxLogo/NxLogoTMobile'),
        },
        {
          name: 'freenet',
          alt: 'Freenet',
          type: 'telco',
          component: () => import('~/components/Nx/NxLogo/NxLogoFreenet'),
        },
        {
          name: 'orange',
          alt: 'Orange',
          type: 'telco',
          component: () => import('~/components/Nx/NxLogo/NxLogoOrange'),
        },
        {
          name: 'zahl-einfach',
          alt: 'Zahl einfach per Handyrechnung',
          type: 'card',
          // This should be a type: 'telco' but we've been asked to
          // display it with the cards in NxOffers and ContentHero.
          // It's a Dutch telcos group) but we group it with the
          // cards to display it more easily.
          component: () => import('~/components/Nx/NxLogo/NxLogoZahl'),
        },
      ],
    }
  },

  computed: {
    availablePaymentMethods() {
      // Dimoco is not a payment method but the API gataway that groups the telco names.
      // Available telco names are in the webConfig's dimoco object.
      return this.$store.state.webConfig.config.availablePaymentMethods.filter(
        method => method !== 'dimoco'
      )
    },

    availableTelco() {
      return this.$store.state.webConfig?.config?.dimoco?.local_telcos || []
    },

    availablePaymentMethodsWithTelco() {
      // We add the telco logos as payment methods
      return this.availablePaymentMethods.concat(this.availableTelco)
    },

    filteredPaymentMethodsByPropsType() {
      if (['bank', 'card', 'telco'].includes(this.showOnly)) {
        return this.availablePaymentMethodsWithTelco.filter(
          method =>
            this.logos.find(logo => logo.name === method)?.type ===
            this.showOnly
        )
      } else {
        return this.availablePaymentMethodsWithTelco
      }
    },

    getAvailableLogosPerMarket() {
      return this.logos.filter(logo =>
        this.filteredPaymentMethodsByPropsType.includes(logo.name)
      )
    },
  },
}
