
import { mapGetters } from 'vuex'

export default {
  props: {
    hasNavIconsLight: { type: Boolean, default: false },
    isTransparent: { type: Boolean, default: false },
    isOverlaid: { type: Boolean, default: false },
  },

  head() {
    return {
      htmlAttrs: {
        class: this.asFullscreenComponent ? 'overflow-hidden' : '',
      },
    }
  },

  computed: {
    ...mapGetters(['asFullscreenComponent']),
  },
}
