interface ModernError {
  error: { code: number; description: string; message: string }
}

interface LegacyError {
  exception: string
  message: string
}

export function isModernError(error: any): error is ModernError {
  return (
    error.error !== undefined &&
    error.error.code !== undefined &&
    error.error.description !== undefined &&
    error.error.message !== undefined &&
    typeof error.error.code === 'number' &&
    typeof error.error.description === 'string' &&
    typeof error.error.message === 'string'
  )
}

/**
 * Converts a modern error to a legacy error, for retro-compatibility with the legacy API returns.
 */
export function errorToLegacyError(errorContainer: ModernError): LegacyError {
  return {
    exception: errorContainer.error.description,
    message: `${errorContainer.error.message} [#${errorContainer.error.code}]`,
  }
}
