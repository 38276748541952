
import { createSlug } from '~/helpers/slug'
import { getRecommendedCoverFromFormats } from '~/helpers/metaElements'

export default {
  props: {
    book: {
      type: Object,
      required: true,
    },

    theme: {
      type: String,
      default: 'light',
      validator: value => ['light', 'dark'].includes(value),
    },

    index: {
      /**
       * The index of the book in the list let us put image loading priorities in a single place,
       * the first ones being the most important.
       * Objective here is to improve the Largest Contentful Paint of the page.
       *
       * This will affect the fecthpriority and lazy loading of the image.
       */
      type: Number,
      default: 0,
    },
  },

  computed: {
    fetchPriority() {
      if (this.index < 3) {
        return 'high'
      } else if (this.index < 6) {
        return 'medium'
      } else {
        return 'low'
      }
    },

    lazyLoading() {
      return this.index < 6 ? 'eager' : 'lazy'
    },

    bookCoverUrl() {
      let coverImg = getRecommendedCoverFromFormats(this.book.formats)?.img_url

      if (coverImg) {
        const coverUrl = new URL(coverImg)

        // Note: Setting both will constraint the size, without changing the aspect ratio
        coverUrl.searchParams.set('w', '250')
        coverUrl.searchParams.set('h', '250')

        if (this.book.is_adult_book && !this.$auth.loggedIn) {
          // Adult books have a special filter, so we can put a warning on top of it 🔞
          // For now, it's only for logged-out users.

          // - It's blurred
          coverUrl.searchParams.set('blur', '200')
          // - Aspect ratio is forced to 1:1
          coverUrl.searchParams.set('ar', '1')
          coverUrl.searchParams.set('fit', 'crop')
          // - Brightness and saturation are reduced
          coverUrl.searchParams.set('bri', '-60')
          coverUrl.searchParams.set('sat', '-45')
        }

        coverImg = coverUrl.toString()
      }

      return coverImg
    },

    bookAuthors() {
      return this.book.authors.map(author => author.name).join(', ')
    },

    bookRating() {
      return Math.round(this.book.average_rating) || 0
    },
  },

  mounted() {
    this.isLoading = false
  },

  methods: {
    createSlug,
  },
}
