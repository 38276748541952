import { AxiosPromise } from 'axios'

import { Receipt } from '../api-types/Receipt'
import BaseApi from '../bridge-api/baseApi'

export class SingleSignOn extends BaseApi {
  /**
   * @see https://nextory.atlassian.net/wiki/spaces/NEX/pages/2291007545/Nextory+SSO+Draft#4.1-API-to-provide-the-sso-meta-information-to-APP%2FWEB
   */
  providers(): AxiosPromise<Receipt[]> {
    return this.http({
      method: 'get',
      url: '/sso/v1/providers',
    })
  }
}
