import { AxiosPromise } from 'axios'

import { CampaignInfo } from '../api-types/CampaignInfo'
import BaseApi from '../bridge-api/baseApi'

export class Campaign extends BaseApi {
  /**
   * Validates a campaign code and a channel against the API.
   * "channel" is optional and can be passed (explicitly) as null when not available/needed. (defaults to "NEXTORY")
   */
  validateCode(
    code: string,
    channel: string | null
  ): AxiosPromise<CampaignInfo> {
    return this.http({
      method: 'post',
      url: `/obgateway/v1/campaign/validate`,
      data: {
        campaign_code: code,
        channel: channel || 'NEXTORY',
      },
    })
  }

  redeemNoPaymentCampaign(
    subscriptionId: number,
    code: string,
    channel: string | null
  ): AxiosPromise<{
    order_id: number
    payment_status: string
  }> {
    channel = channel || 'NEXTORY'

    return this.http({
      method: 'post',
      url: `/obgateway/v1/campaign/redeem`,
      data: {
        campaign_code: code,
        channel,
        subscription_id: subscriptionId,
      },
    })
  }

  infoFromSlug(slug: string): AxiosPromise<{
    code: string
    cover_url: string
    frequency: 'DAY' | 'WEEK' | 'MONTH' | 'YEAR'
    header_text: string
    id: number
    name: string
    no_of_occurrences: number
    partner_logo_url: string
    provider: string
    slug_name: string
    tag_text: string
    trial_days: number
    utm_params: string
  }> {
    return this.http({
      method: 'get',
      url: `/order/v1/offers/${slug}`,
    })
  }
}
