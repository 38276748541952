
import fullheight from '~/mixins/fullheight'
import head from '~/mixins/head'
import { getCountryNameByCode } from '~/mixins/localized'
import tracker from '~/mixins/tracker'

export default {
  mixins: [fullheight, head, tracker],

  middleware: 'auth',

  data() {
    return {
      account: {},
    }
  },

  async fetch() {
    await this.fetchAccount()
  },

  computed: {
    getEmail() {
      return this.account?.email
    },

    country() {
      return getCountryNameByCode(this.account?.country, this.$i18n)
    },
  },

  created() {
    // refresh account data when email is updated
    this.$root.$on('update-email-fetch-account', this.fetchAccount)
  },

  beforeDestroy() {
    this.$root.$off('update-email-fetch-account', this.fetchAccount)
  },

  methods: {
    logout() {
      this.$auth.logout()
    },

    async fetchAccount() {
      this.account = (await this.$hummingbird.me.getAccount()).data
    },
  },
}
