/**
 * This middleware redirects all the old Youboox catalog URLs to the corresponding ones in NX5.
 *
 * You may be wondering why I didn't make an individual Vue file for each redirect...
 * The reason is that we'll need to handle plenty of them, but Nuxt gets slower and slower with each page added,
 * due to a vue-router performance issue.
 *
 * Doing it in a middleware helps preserve our current performance.
 *
 * Note: This strategy could be done for other "redirect-only" pages that we have.
 *
 * Examples of URLs handled by this middleware:
 * - /fr/catalog/books/1234-the-book-title
 * - /be-en/catalog/books/1234-the-book-title
 * - /fr/catalog/selections/123-the-selection-title
 * - /be-en/catalog/selections/123-the-selection-title
 * - /fr/catalog/categories/1234-the-category-title
 * - /be-en/catalog/categories/1234-the-category-title
 * - /fr/catalog/authors/1234-the-author-name
 * - /be-en/catalog/authors/1234-the-author-name
 * - /fr/catalog/series/1234-the-series-name
 * - /be-en/catalog/series/1234-the-series-name
 * - /fr/catalog
 * - /be-en/catalog
 */
export default function ({ localePath, redirect, req }) {
  if (!process.server || !req?.url) return

  const redirects = [
    {
      // Book pages
      // URL:         /catalog/books/<id>-<slug>
      // Redirect to: /migration/book/<id>
      regex: /^\/([a-z]{2}(?:-[a-z]{2})?)\/catalog\/books\/([0-9]+)-(.*)$/,
      route: ([, , id]) => ({
        name: 'migration-book-id',
        params: { id },
      }),
    },
    {
      // Selections pages
      // URL:         /catalog/selections/<slug>
      // Redirect to: /categories/books
      regex: /^\/([a-z]{2}(?:-[a-z]{2})?)\/catalog\/selections\/(.*)$/,
      route: () => ({
        name: 'categories-books',
      }),
    },
    {
      // Categories pages
      // URL:         /catalog/categories/<id>-<slug>
      // Redirect to: /migration/category/<slug>
      regex: /^\/([a-z]{2}(?:-[a-z]{2})?)\/catalog\/categories\/([0-9]+)-(.*)$/,
      route: ([, , , slug]) => ({
        name: 'migration-category-slug',
        params: { slug },
      }),
    },
    {
      // Authors pages
      // URL:         /catalog/authors/<id>-<slug>
      // Redirect to: /search?q=<slug>
      regex: /^\/([a-z]{2}(?:-[a-z]{2})?)\/catalog\/authors\/([0-9]+)-(.*)$/,
      route: ([, , , slug]) => ({
        name: 'search',
        query: { q: slug.replace(/-/g, ' ') },
      }),
    },
    {
      // Series pages
      // URL:         /catalog/series/<id>-<slug>
      // Redirect to: /search?q=<slug>
      regex: /^\/([a-z]{2}(?:-[a-z]{2})?)\/catalog\/series\/([0-9]+)-(.*)$/,
      route: ([, , , slug]) => ({
        name: 'search',
        query: { q: slug.replace(/-/g, ' ') },
      }),
    },
    {
      // Catalog homepage (must be checked last)
      // URL:         /catalog
      // Redirect to: /categories/books
      regex: /^\/([a-z]{2}(?:-[a-z]{2})?)\/catalog(\/.*)?$/,
      route: () => ({
        name: 'categories-books',
      }),
    },
  ]

  for (const { regex, route } of redirects) {
    // Match without query parameters (we'll restore them later)
    const [path, query] = req.url.split('?', 2)
    const match = path.match(regex)
    if (match) {
      let to = localePath(route(match))

      // Restore query parameters (if any)
      if (query) {
        to += `?${query}`
      }

      return redirect(
        301, // Permanent redirect
        to
      )
    }
  }
}
