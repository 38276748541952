const state = () => ({
  config: {
    abtestUrls: ['nextory.com/fi', 'nextory.com/fi-en'],
    trialDays: 14,
    preselectedSubscription: 'SILVER',
    availablePaymentMethods: ['visa', 'mc', 'amex', 'paypal', 'sofort'],
    features: { hasMagazines: false },
    minimumPrice: { amount: 9.99, currency: 'EUR' },
    maximumPrice: { amount: 31.99, currency: 'EUR' },
  },
  entryUrl: 'nextory.com/fi',
})

export default {
  state,
}
