
export default {
  props: {
    error: { type: Object, default: null },

    // Title and message props let you customize the default error texts, whenever needed.
    // Otherwise, some logic is there to display the default error (if you're on a 404 page, or if an issue happened)
    title: { type: String, default: null },

    message: { type: String, default: null },
  },

  computed: {
    is404() {
      return this.error?.statusCode === 404
    },

    errorTitle() {
      if (this.title) {
        return this.title
      } else if (this.is404) {
        return this.$t('404.title')
      }

      return this.$t('global.error.title')
    },

    errorBody() {
      if (this.message) {
        return this.message
      } else if (this.is404) {
        return this.$t('404.body')
      }

      return this.error?.message
    },

    ctaUrl() {
      return this.error?.url || this.localePath({ name: 'index' })
    },

    ctaText() {
      return this.error?.cta || this.$t('404.cta')
    },
  },

  mounted() {
    if (this.is404 && this.$sentry) {
      const err = new Error('Page not found', {
        cause: this.error,
      })
      this.$sentry.withScope(scope => {
        // group all page not found errors together
        scope.setFingerprint(['Page not found'])
        this.$sentry.captureException(err)
      })
    }
  },
}
