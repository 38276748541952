export default function (to, from, savedPosition) {
  // we want to scroll to top when navigating to or from the register page
  // to ensure the user sees the top of the page
  if (to.path.includes('/register') || from.path.includes('/register')) {
    return { x: 0, y: 0 }
  }

  // we keep the default behavior for all other pages
  if (savedPosition) {
    return savedPosition
  } else {
    return { x: 0, y: 0 }
  }
}
