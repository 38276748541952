
import { mapMutations } from 'vuex'
import head from '~/mixins/head'
import tracker from '~/mixins/tracker'

export default {
  mixins: [head, tracker],

  props: {
    error: { type: Object, default: null },
  },

  mounted() {
    this.SET_STEP(-1)
  },

  methods: {
    ...mapMutations(['SET_STEP']),
  },
}
