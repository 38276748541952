import flashMessage from './modules/flashMessage'
import opportunities from './modules/opportunities'
import steps from './modules/steps'
import accountProfiles from './modules/accountProfiles'
import flash from './modules/flash'
import layout from './modules/layout'
import payment from './modules/payment'
import webConfig from './modules/webConfig'

export const strict = false

export const modules = {
  accountProfiles,
  flash,
  flashMessage,
  layout,
  opportunities,
  steps,
  payment,
  webConfig,
}
