import consola from 'consola'
import { NextoryContext } from '~/nx-types'
const logger = consola.withScope('Helpers - localized')

/*
  This function is used to format price in the format of the user's locale.
  If the price is not valid, an error is sent to Sentry and we return an empty string.
*/
export function getLocalizedPrice(
  price: number,
  currencyCode: string,
  { $sentry, i18n }: NextoryContext
): string {
  try {
    return new Intl.NumberFormat(i18n.localeProperties.iso, {
      style: 'currency',
      currency: currencyCode,
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    }).format(price)
  } catch (e) {
    $sentry.captureException(e)
    return ''
  }
}

/*
  This function is used to format date in the format of the user's locale.
  If the date is not valid, an error is sent to Sentry and we return an empty string.
*/
export function getLocalizedDate(
  date: string,
  { $sentry, i18n }: NextoryContext,
  options?: Intl.DateTimeFormatOptions
): string {
  try {
    return new Intl.DateTimeFormat(i18n.localeProperties.iso, options).format(
      Date.parse(date)
    )
  } catch (e) {
    $sentry.captureException(e)
    return ''
  }
}

/*
  This function is used to format date in a specific format (YYYY-MM-DD).

  It's mostly used so our date-localization functions can work with timestamps,
  as well as JSON-LD that requires this format.
 */
export function timestampToDate(timestamp: number): string {
  return new Date(timestamp).toISOString().split('T')[0]
}

/*
   Transform the duration in seconds to a valid ISO 8601 time duration format
*/
export function secondsToDurationString(duration: number): string {
  const hour: string = Math.floor(duration / 3600)
    .toString()
    .padStart(2, '0')
  const min: string = Math.floor((duration % 3600) / 60)
    .toString()
    .padStart(2, '0')
  const sec: string = Math.floor(duration % 60)
    .toString()
    .padStart(2, '0')

  return `PT${hour}H${min}M${sec}S`
}

/*
  This function is used to format duration in the format of the user's locale.
*/
export function formatDuration(sec: number): { hour: number; min: number } {
  const hour: number = Math.floor(sec / 3600)
  const min: number = Math.floor((sec / 3600 - hour) * 60)
  return { hour, min }
}

/*
  This function is used to format duration in the format of the user's locale.
*/
export function getTimeSpent({ i18n }: NextoryContext, sec: number) {
  const { hour, min } = formatDuration(sec)

  if (hour === 0)
    return i18n.t('mypages.time_restricted.time_consumed_min', { min })

  return i18n.t('mypages.time_restricted.time_consumed', { hour, min })
}

/**
 * Turns an iso (eg. en-US) into an object with language and country
 */
export function parseLocaleIso(localeIso: string) {
  const [language, country] = localeIso.split('-')
  return { language, country }
}
