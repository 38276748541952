// profile state module
const state = () => ({
  colors: [],
  maxLoginCount: undefined,
  maxProfiles: undefined,
  profiles: [],
})

const getters = {
  getAccountMaxProfiles(state) {
    return state.maxProfiles
  },

  getAccountLoginCount(state) {
    return state.maxLoginCount
  },

  getAvailableColors(state) {
    return state.colors
  },

  getAccountProfiles(state) {
    return state.profiles
  },

  hasUnsetProfile(state) {
    return state.profiles.length < state.maxProfiles
  },

  hasMoreThanOneProfile(state) {
    // We're counting the main account profile and the first shared profile.
    return state.profiles.length > 1
  },
}

const mutations = {
  SET_PROFILES(state, { colors, maxLoginCount, maxProfiles, profiles }) {
    state.colors = colors
    state.maxLoginCount = maxLoginCount
    state.maxProfiles = maxProfiles
    state.profiles = profiles
  },
}

const actions = {
  async fetchAccount({ commit, state }) {
    const {
      colors,
      max_login_count: maxLoginCount,
      max_profiles: maxProfiles,
      profiles,
    } = (await this.$hummingbird.profiles.getProfiles()).data

    commit('SET_PROFILES', { colors, maxLoginCount, maxProfiles, profiles })
  },

  async fetchAccountProfiles({ dispatch, state }) {
    await dispatch('fetchAccount')

    return state.profiles
  },

  async getAccountMainProfileLoginKey({ dispatch, state }) {
    await dispatch('fetchAccount')

    return state.profiles[0].login_key
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
