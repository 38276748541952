import { Plugin, Context } from '@nuxt/types'
import { Inject } from '@nuxt/types/app'
import consola from 'consola'
import Hummingbird from '~/plugins/hummingbird'

const logger = consola.withScope('Hummingbird plugin')

const nxHummingbirdPlugin: Plugin = (
  { app: { $config, i18n }, req }: Context,
  inject: Inject
) => {
  if (
    [
      $config.NEXTORY_API_ENDPOINT,
      $config.USER_AGENT,
      $config.CONSOLA_LEVEL,
      i18n.localeProperties.iso ?? undefined,
    ].includes(undefined)
  ) {
    logger.error('Missing configuration for Hummingbird, got:', {
      NEXTORY_API_ENDPOINT: $config.NEXTORY_API_ENDPOINT,
      USER_AGENT: $config.USER_AGENT,
      CONSOLA_LEVEL: $config.CONSOLA_LEVEL,
      'i18n.localeProperties.iso': i18n.localeProperties.iso ?? undefined,
    })
  }

  const isHttps = ['production', 'staging'].includes(
    process.env.ENVIRONMENT || ''
  )
  const origin = req
    ? (isHttps ? 'https' : 'http') + '://' + req.headers.host
    : window.origin

  const hummingbirdClient = new Hummingbird(
    origin,
    $config.NEXTORY_API_ENDPOINT,
    $config.USER_AGENT,
    i18n.localeProperties.iso as string
  )

  hummingbirdClient.setLogLevel($config.CONSOLA_LEVEL)

  inject('hummingbird', hummingbirdClient)
}

export default nxHummingbirdPlugin
