
import ImgixClient from '@imgix/js-core'

export default {
  props: {
    height: { type: [String, Number], default: '128' },
    width: { type: [String, Number], default: '128' },
    src: { type: String, required: true },
    alt: { type: String, required: true },
    lazy: { type: Boolean, default: true },
  },

  data() {
    return {
      imgLoad: false,
      options: {
        w: this.width,
        h: this.height,
        auto: 'format',
      },
      minMaxWidth: {
        minWidth: Math.min(Number(this.width), 320),
        maxWidth: Number(this.width),
      },
      ixSrcset: null,
      ixSrc: null,
    }
  },

  fetch() {
    // If we have an absolute URL, we won't be able to use the ImgixClient, so we'll just use the src as is.
    if (this.src.startsWith('http')) {
      this.ixSrc = this.src
      return
    }

    const ixClient = new ImgixClient({
      domain: 'nextory-assets.imgix.net',
    })

    this.ixSrcset = ixClient.buildSrcSet(
      this.src,
      { auto: 'format' },
      this.minMaxWidth
    )
    this.ixSrc = ixClient.buildURL(this.src, this.options, this.minMaxWidth)
  },
}
