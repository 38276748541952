
import fullheight from '~/mixins/fullheight'
import head from '~/mixins/head'
import tracker from '~/mixins/tracker'

export default {
  mixins: [fullheight, head, tracker],

  data() {
    return {
      isProud: /* June = pride month */ new Date().getMonth() === 5,
    }
  },

  computed: {
    quote() {
      if (this.isProud) {
        return this.$t('login.desktop.quote.title.pride')
      }

      return this.$t('login.desktop.quote.title')
    },

    author() {
      if (this.isProud) {
        return this.$t('login.desktop.quote.author.pride')
      }

      return null
    },
  },

  mounted() {
    // eslint-disable-next-line no-console -- This is an easter egg
    console.log(
      '🌈📚 Nextory stands with human rights and embraces the beauty of diversity. Love always wins!'
    )
  },
}
