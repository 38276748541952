import { AxiosPromise, AxiosRequestConfig, Method } from 'axios'

import BaseApi from '../bridge-api/baseApi'

const collectBrowserInfo = () => {
  // @source: https://github.com/Adyen/adyen-3ds2-js-utils/blob/main/browser/index.js

  const screenWidth = window && window.screen ? window.screen.width : ''
  const screenHeight = window && window.screen ? window.screen.height : ''
  const colorDepth = window && window.screen ? window.screen.colorDepth : ''
  const userAgent = window && window.navigator ? window.navigator.userAgent : ''
  const javaEnabled =
    window && window.navigator ? navigator.javaEnabled() : false

  let language = ''
  if (window && window.navigator) {
    language = window.navigator.language // Else is for IE <+ 10
  }

  const d = new Date()
  const timeZoneOffset = d.getTimezoneOffset()

  return {
    acceptHeader: '*/*',
    screenWidth,
    screenHeight,
    colorDepth,
    userAgent,
    timeZoneOffset,
    language,
    javaEnabled,
  }
}

function buildReturnUrl() {
  const returnUrl = new URL(window.location.href)
  returnUrl.searchParams.delete('status')
  returnUrl.searchParams.delete('orderId')
  returnUrl.searchParams.delete('orderid')
  returnUrl.searchParams.delete('order_id')
  // The following are related to Adyen Drop-In redirect flows.
  returnUrl.searchParams.delete('sessionId')
  returnUrl.searchParams.delete('redirectResult')

  return (
    returnUrl.toString() +
    // I don't want to talk about this. It's related to the back-end API.
    (window.location.search ? '&' : '?')
  )
}

function handleChangePaymentMethod(
  isChangingPaymentMode: boolean,
  dataRef: any
) {
  let method: Method = 'post'
  let url = '/obgateway/v1/register/checkout'

  if (isChangingPaymentMode) {
    // When changing payment method, endpoint and mode change.
    method = 'put'
    url = '/obgateway/v1/me/payment_infos'
    // When changing payment method, some fields must be removed.
    dataRef.campaign_code = undefined
    dataRef.subscription_id = undefined
    dataRef.channel = undefined
    dataRef.payment_info.return_url += 'context=change&'
  }

  return { method, url }
}

function buildPaymentRequest(
  channel: string = 'NEXTORY',
  gateway: string,
  paymentInfo: object,
  subscriptionId: number | string,
  campaignCode: string | undefined = undefined,
  isChangingPaymentMode: boolean = false
): AxiosRequestConfig {
  channel = channel || 'NEXTORY'

  const data = {
    campaign_code: campaignCode || '',
    browserInfo: collectBrowserInfo(),
    subscription_id: Number(subscriptionId),
    channel,
    gateway,
    payment_info: paymentInfo,
    utm: {
      medium: '',
      campaign: '',
      content: '',
      source: '',
      term: '',
    },
  }
  const { method, url } = handleChangePaymentMethod(isChangingPaymentMode, data)

  return { method, url, data }
}

export class Payment extends BaseApi {
  // This function work with the following gateway SOFORT/GIROPAY/TRUSTLY
  payWithRedirect(
    campaignCode: string = '',
    subscriptionId: number | string,
    gateway: 'SOFORT' | 'GIROPAY' | 'TRUSTLY',
    isChangingPaymentMode: boolean = false,
    channel: string
  ): AxiosPromise<{
    order_id: number
    payment_status: string
    redirect_url: string
  }> {
    return this.http(
      buildPaymentRequest(
        channel,
        gateway,
        {
          return_url: buildReturnUrl(),
        },
        subscriptionId,
        campaignCode,
        isChangingPaymentMode
      )
    )
  }

  /**
   * This function is used to pay with a phone number using the DIMOCO gateway.
   * The user will receive an SMS with a TAN code, which they will have to enter to complete the payment.
   */
  payWithDimoco(
    phoneNumber: string,
    campaignCode: string = '',
    subscriptionId: number | string,
    isChangingPaymentMode: boolean = false,
    channel: string
  ): AxiosPromise<{
    action?: string
    order_id: number
    order_reference: string
    payment_status: string
  }> {
    if (process.env.ENVIRONMENT !== 'production') {
      /**
       * In a non-production environment, we want to mock the response for certain phone numbers, so we can try the flow.
       */

      const testPhoneNumbers = {
        challenge: '+1111', // User will be asked for an SMS code
        error: '+7777', // Error response
      }

      if (phoneNumber === testPhoneNumbers.challenge) {
        // Return a mocked response
        return new Promise(resolve => {
          setTimeout(() => {
            resolve({
              status: 200,
              statusText: 'OK',
              headers: {},
              config: {},
              data: {
                action: 'TAN_FLOW',
                order_id: 999,
                order_reference: 'mocked_order_reference',
                payment_status: 'PENDING',
              },
            })
          }, 1000)
        }) as AxiosPromise
      } else if (phoneNumber === testPhoneNumbers.error) {
        // Return a mocked error
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            reject({
              status: 400,
              statusText: 'Bad Request',
              headers: {},
              config: {},
              data: {
                error: {
                  code: 4004,
                  key: 'MockedError',
                  description: 'Mocked error message',
                  message: 'Error.MockedError',
                },
              },
            })
          }, 1000)
        }) as AxiosPromise
      }
    }

    return this.http(
      buildPaymentRequest(
        channel,
        'DIMOCO',
        {
          // International format phone number
          msisdn: phoneNumber,
        },
        subscriptionId,
        campaignCode,
        isChangingPaymentMode
      )
    )
  }

  /**
   * This function is used to validate the TAN code that the user receives via SMS.
   * Note: TAN = Transaction Authentication Number
   */
  validateDimocoChallenge(
    tanCode: string,
    orderId: number | string,
    orderReference: string
  ): AxiosPromise<{
    order_id: number
    order_reference: string
    payment_status: 'SUCCESS'
  }> {
    if (process.env.ENVIRONMENT !== 'production') {
      /**
       * In a non-production environment, we want to mock the response for certain TAN codes, so we can try the flow.
       */

      if (tanCode === 'VALID') {
        return new Promise(resolve => {
          setTimeout(() => {
            resolve({
              status: 200,
              statusText: 'OK',
              headers: {},
              config: {},
              data: {
                order_id: 999,
                order_reference: 'mocked_order_reference',
                payment_status: 'SUCCESS',
              },
            })
          }, 1000)
        }) as AxiosPromise
      } else if (tanCode === 'INVALID') {
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            reject({
              status: 400,
              statusText: 'Bad Request',
              headers: {},
              config: {},
              data: {
                error: {
                  code: 4004,
                  key: 'MockedError',
                  description: 'Mocked error message',
                  message: 'Error.MockedError',
                },
              },
            })
          }, 1000)
        }) as AxiosPromise
      }
    }

    return this.http({
      method: 'post',
      url: '/obgateway/v1/register/checkout/dimoco',
      data: {
        order_id: orderId,
        tan: tanCode,
        order_reference: orderReference,
      },
    })
  }

  /**
   * This (preferably) should be called when the user cancels the TAN challenge.
   */
  cancelDimocoChallenge(
    orderId: number | string,
    orderReference: string
  ): AxiosPromise<any> {
    return this.http({
      method: 'delete',
      url: '/obgateway/v1/register/checkout/dimoco',
      data: {
        order_id: orderId,
        order_reference: orderReference,
      },
    })
  }

  payWithIdeal(
    subscriptionId: number | string,
    issuerId: string,
    campaignCode: string = '',
    isChangingPaymentMode: boolean = false,
    channel: string
  ): AxiosPromise<{
    order_id: number
    payment_status: string
    redirect_url: string
  }> {
    return this.http(
      buildPaymentRequest(
        channel,
        'IDEAL',
        {
          issuer: issuerId,
          return_url: buildReturnUrl(),
        },
        subscriptionId,
        campaignCode,
        isChangingPaymentMode
      )
    )
  }

  payWithPaypal(
    subscriptionId: number | string,
    nonce: string,
    campaignCode: string = '',
    isChangingPaymentMode: boolean = false,
    channel: string
  ): AxiosPromise<{
    order_id: number
    payment_status: string
  }> {
    return this.http(
      buildPaymentRequest(
        channel,
        'PAYPAL',
        {
          payPalNonce: nonce,
        },
        subscriptionId,
        campaignCode,
        isChangingPaymentMode
      )
    )
  }

  checkoutInformation(
    subscriptionId: string | number,
    campaignCode: string = '',
    channel: string = 'WEB',
    cardUpdate: boolean = false
  ): AxiosPromise<{
    gateways: {
      amount: number
      clientKey?: string // For Adyen
      currency: string
      gateway: string
      issuers?: {
        // For iDEAL
        id: string
        name: string
      }[]
    }[]
    payment_required: boolean
  }> {
    channel = channel || 'WEB'

    const params = new URLSearchParams({
      subscription_id: encodeURIComponent(subscriptionId),
      campaign_code: encodeURIComponent(campaignCode || ''),
      channel: encodeURIComponent(channel),
      card_update: encodeURIComponent(cardUpdate),
    })

    return this.http({
      method: 'get',
      url: '/obgateway/v1/register/checkout?' + params.toString(),
    })
  }

  dropinCreateSession(
    subscriptionId: number,
    campaignCode: string = '',
    channel: string = 'WEB',
    returnUrl: string,
    isChangingPaymentMode: boolean = false
  ): AxiosPromise<{
    amount: {
      currency: string
      value: number
    }
    expiresAt: string
    order_id: number
    payment_status: string
    session_data: string
    session_id: string
  }> {
    channel = channel || 'WEB'

    return this.http({
      method: isChangingPaymentMode ? 'put' : 'post',
      url: '/obgateway/v1/register/payment/adyen/sessions',
      data: {
        campaign_code: campaignCode,
        subscription_id: subscriptionId,
        channel,
        payment_info: {
          return_url: returnUrl,
        },
      },
    })
  }

  authenticationResult3DS(adyenResult3DS: {
    challengeResult: string
    fingerprintResult: string
    orderId: number
    payload: string
  }): AxiosPromise<any> {
    return this.http({
      method: 'post',
      url: '/obgateway/v1/checkout/3dsresult',
      data: {
        fingerprint_result: adyenResult3DS.fingerprintResult,
        challenge_result: adyenResult3DS.challengeResult,
        order_id: adyenResult3DS.orderId,
        payload: adyenResult3DS.payload,
        giftcardPurchase: false,
      },
    })
  }
}
