import type { Context } from '@nuxt/types'
import Vue from 'vue'
import cookie, { CookieSerializeOptions } from 'cookie'
import { decodeValue, encodeValue, getProp, isSet, isUnset } from './utils'

const STORE_NAMESPACE = 'auth'
const STORAGE_PREFIX = STORE_NAMESPACE + '.'
const COOKIE_OPTIONS = {
  domain: process.env.NEXTORY_DOMAIN,
  expires: 30 /* days */,
} as Omit<CookieSerializeOptions, 'expires'> & {
  expires: Date | number | undefined
}
const INITIAL_STATE = {
  loggedIn: false,
  user: null,
}

export class Storage {
  public state: any

  constructor(public ctx: Context) {
    this.ctx = ctx

    this._initState()
  }

  // ------------------------------------
  // Universal
  // ------------------------------------

  setUniversal<V extends unknown>(key: string, value: V): V | void {
    // Unset null, undefined
    if (isUnset(value)) {
      return this.removeUniversal(key)
    }

    // Cookies
    this.setCookie(key, value)

    // Local Storage
    this.setLocalStorage(key, value)

    // Local state
    this.setState(key, value)

    return value
  }

  getUniversal(key: string): unknown {
    let value

    // Local state
    if (process.server) {
      value = this.getState(key)
    }

    // Cookies
    if (isUnset(value)) {
      value = this.getCookie(key)
    }

    // Local Storage
    if (isUnset(value)) {
      value = this.getLocalStorage(key)
    }

    // Local state
    if (isUnset(value)) {
      value = this.getState(key)
    }

    return value
  }

  syncUniversal(key: string, defaultValue?: unknown): unknown {
    let value = this.getUniversal(key)

    if (isUnset(value) && isSet(defaultValue)) {
      value = defaultValue
    }

    if (isSet(value)) {
      this.setUniversal(key, value)
    }

    return value
  }

  removeUniversal(key: string): void {
    this.removeState(key)
    this.removeLocalStorage(key)
    this.removeCookie(key)
  }

  // ------------------------------------
  // Local state (reactive)
  // ------------------------------------

  _initState(): void {
    // Use vuex for local state
    const storeModule = {
      namespaced: true,
      state: () => Object.assign({}, INITIAL_STATE), // deep-copy (must be done to avoid state leaks)
      mutations: {
        SET(state: object, payload: { key: string | number; value: any }) {
          Vue.set(state, payload.key, payload.value)
        },
      },
    }

    this.ctx.store.registerModule(STORE_NAMESPACE, storeModule)

    this.state = this.ctx.store.state[STORE_NAMESPACE]
  }

  setState<V extends unknown>(key: string, value: V): V {
    this.ctx.store.commit(STORE_NAMESPACE + '/SET', {
      key,
      value,
    })

    return value
  }

  getState(key: string): unknown {
    return this.state[key]
  }

  watchState(
    key: string,
    fn: (value: unknown, oldValue: unknown) => void
  ): () => void {
    return this.ctx.store.watch(
      state => getProp(state[STORE_NAMESPACE], key),
      fn
    )
  }

  removeState(key: string): void {
    this.setState(key, undefined)
  }

  // ------------------------------------
  // Local storage
  // ------------------------------------

  setLocalStorage<V extends unknown>(key: string, value: V): V | void {
    // Unset null, undefined
    if (isUnset(value)) {
      return this.removeLocalStorage(key)
    }

    if (!this.isLocalStorageEnabled()) {
      return
    }

    const _key = this.getPrefix() + key

    localStorage.setItem(_key, encodeValue(value))

    return value
  }

  getLocalStorage(key: string): unknown {
    if (!this.isLocalStorageEnabled()) {
      return
    }

    const _key = this.getPrefix() + key

    const value = localStorage.getItem(_key)

    return decodeValue(value)
  }

  removeLocalStorage(key: string): void {
    if (!this.isLocalStorageEnabled()) {
      return
    }

    const _key = this.getPrefix() + key

    localStorage.removeItem(_key)
  }

  // ------------------------------------
  // Cookies
  // ------------------------------------
  getCookies(): Record<string, unknown> {
    if (!this.isCookiesEnabled()) {
      return {}
    }
    const cookieStr = process.client
      ? document.cookie
      : this.ctx.req.headers.cookie

    return cookie.parse(cookieStr || '') || {}
  }

  setCookie<V extends unknown>(
    key: string,
    value: V,
    options: { prefix?: string } = {}
  ): V {
    if (process.server && !this.ctx.res) {
      return value
    }

    if (!this.isCookiesEnabled()) {
      return value
    }

    const _key = STORAGE_PREFIX + key
    const _options = Object.assign({}, COOKIE_OPTIONS, options)
    const _value = encodeValue(value)

    _options.path = '/'

    // Unset null, undefined
    if (isUnset(value)) {
      _options.maxAge = -1
    }

    // Accept expires as a number for js-cookie compatibility
    if (typeof _options.expires === 'number') {
      _options.expires = new Date(Date.now() + _options.expires * 864e5)
    }

    // We have to tell TS that expires is a Date|undefined now, number have been checked already
    const _optionsWithDate = _options as CookieSerializeOptions & {
      expires: Date | undefined
    }

    const serializedCookie = cookie.serialize(_key, _value, _optionsWithDate)

    if (process.client) {
      // Set in browser
      document.cookie = serializedCookie
    } else if (process.server && this.ctx.res) {
      // Send Set-Cookie header from server side
      let cookies = (this.ctx.res.getHeader('Set-Cookie') as string[]) || []
      if (!Array.isArray(cookies)) cookies = [cookies]
      cookies.unshift(serializedCookie)
      this.ctx.res.setHeader(
        'Set-Cookie',
        cookies.filter(
          (v, i, arr) =>
            arr.findIndex(val =>
              val.startsWith(v.substr(0, v.indexOf('=')))
            ) === i
        )
      )
    }

    return value
  }

  getCookie(key: string): unknown {
    if (process.server && !this.ctx.req) {
      return
    }

    if (!this.isCookiesEnabled()) {
      return
    }

    const _key = STORAGE_PREFIX + key

    const cookies = this.getCookies()

    const value = cookies[_key]
      ? decodeURIComponent(cookies[_key] as string)
      : undefined

    return decodeValue(value)
  }

  removeCookie(key: string, options?: { prefix?: string }): void {
    this.setCookie(key, undefined, options)
  }

  getPrefix(): string {
    return STORAGE_PREFIX
  }

  isLocalStorageEnabled(): boolean {
    // Local Storage only exists in the browser
    if (!process.client) {
      return false
    }

    // There's no great way to check if localStorage is enabled; most solutions
    // error out. So have to use this hacky approach :\
    // https://stackoverflow.com/questions/16427636/check-if-localstorage-is-available
    const test = 'test'
    try {
      localStorage.setItem(test, test)
      localStorage.removeItem(test)
      return true
    } catch (e) {
      return false
    }
  }

  isCookiesEnabled(): boolean {
    // Server can only assume cookies are enabled, it's up to the client browser
    // to create them or not
    if (process.server) {
      return true
    }

    if (window.navigator.cookieEnabled) {
      return true
    } else {
      // eslint-disable-next-line no-console
      console.warn(
        "[AUTH] Cookies is enabled in config, but browser doesn't" +
          ' support it'
      )
      return false
    }
  }
}
