/*
 * Usage as a raw functions
 */

/** @deprecated */
export function getFirstPaymentDateFromNow(daysNumber, $i18n) {
  if (!daysNumber) {
    return undefined
  }

  let date = new Date(Date.now())
  date.setDate(date.getDate() + daysNumber)
  return new Intl.DateTimeFormat($i18n.localeProperties.iso, {
    month: 'long',
    day: 'numeric',
  }).format(date)
}

/**
 * TODO: Must be renamed in another merge request. Warning: Will probably conflict with the already-existing getLocalizedPrice.
 * @deprecated
 */
export function getlocalizedPrice(price, currencyCode, $i18n) {
  return new Intl.NumberFormat($i18n.localeProperties.iso, {
    style: 'currency',
    currency: currencyCode,
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(price)
}

/**
 * @param date
 * @param $i18n {any}
 * @param options {DateTimeFormatOptions}
 * @returns {string}
 * @deprecated
 */
export function getLocalizedDate(date, $i18n, options = undefined) {
  return new Intl.DateTimeFormat($i18n.localeProperties.iso, options).format(
    Date.parse(date)
  )
}

/** @deprecated */
export function getCountryNameByCode(code, $i18n) {
  try {
    return new Intl.DisplayNames([$i18n.localeProperties.iso], {
      type: 'region',
    }).of(code)
  } catch (_) {
    // Fallback for the rare browsers where it's not supported
    return code
  }
}

/**
 * Usage as a mixin
 * @deprecated
 */
export default {
  methods: {
    getFirstPaymentDateFromNow(daysNumber) {
      return getFirstPaymentDateFromNow(daysNumber, this.$i18n)
    },
    getlocalizedPrice(price, currencyCode) {
      return getlocalizedPrice(price, currencyCode, this.$i18n)
    },
    getLocalizedDate,
  },
}
