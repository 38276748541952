
import { mapActions } from 'vuex'

export default {
  props: {
    message: { type: String, default: null },
    variant: { type: String, default: 'success' },
  },

  data() {
    return {
      showMessage: this.message !== null,
      timer: null,
      flashTimeoutTimer: 5000,
    }
  },

  watch: {
    message(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.resetTimer()
        this.setFlashTimeout()
      }
    },
  },

  destroyed() {
    this.resetTimer()
  },

  methods: {
    ...mapActions(['flashReset']),

    setFlashTimeout() {
      this.timer = setTimeout(() => {
        this.hideFlashMessage()
      }, this.flashTimeoutTimer)
    },

    hideFlashMessage() {
      this.showMessage = false
      this.flashReset(this.variant)
      this.resetTimer()
    },

    resetTimer() {
      clearTimeout(this.timer)
      this.timer = null
    },
  },
}
