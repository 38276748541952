// middleware/entryUrl.js
export default function ({ route, store }) {
  /* Set the entryURL in the project store.
   *
   * This is used to load (or not) the VWO script depending
   * on the abtestUrls REGEX in the marketProperties files.
   *
   * This configuration file is loaded in the store from
   * our Google Cloud Storage bucket.
   */
  store.commit('SET_ENTRY_URL', route.fullPath)
}
