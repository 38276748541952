
import { mapGetters, mapMutations } from 'vuex'
import fullheight from '~/mixins/fullheight'
import head from '~/mixins/head'
import tracker from '~/mixins/tracker'

export default {
  name: 'DefaultLayout',

  mixins: [fullheight, head, tracker],

  computed: {
    ...mapGetters(['getCurrentStep']),
  },

  methods: {
    ...mapMutations(['SET_STEP']),
  },
}
