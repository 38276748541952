// Emulates @nuxtjs/auth default behavior
export default function ({ $auth, app: { localePath, router }, redirect }) {
  if (!$auth.loggedIn) {
    redirect(
      localePath({
        name: 'profile-login',
        // User will be redirected to this page after login
        query: { cb: router.currentRoute.fullPath },
      })
    )
  }
}
