export default {
  computed: {
    isMobile() {
      /*
       * In order to prevent users of mobile applications from
       * navigating through the Nextory website when they want to
       * read our privacy policy or terms of use, we have decided
       * to remove the header and footer of web pages using the
       * query parameters ?mobile=true.
       */
      return this.$route.query.mobile === 'true'
    },

    /*
     * For some special pages, we want to disable the header and footer,
     * but still allow interacting with the page:
     * - clicking links
     * - open FAQ entries
     * - ...
     *
     * In that case, check if `allow_interaction` is set.
     */
    allowInteraction() {
      return this.$route.query.allow_interaction === 'true'
    },
  },
}
