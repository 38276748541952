
export default {
  props: {
    containerClass: { type: String, default: '' },
    contentWidth: {
      type: String,
      default: '',
      validator(value) {
        return ['', 'small'].includes(value)
      },
    },
    isFullheight: {
      type: Boolean,
      default: false,
    },
    hasHelp: {
      type: Boolean,
      default: false,
    },
    hasLogo: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    contentWidthClass() {
      const sizes = {
        // Explicitly list classes, so they're not purged by PurgeCSS mistakenly
        small: 'content-width--small',
      }

      return sizes[this.contentWidth] || ''
    },
  },
}
