const state = () => ({
  isPaymentLoading: false,
  isPaymentValid: false,
  showBeforePaymentInterstitial: true,
})

const getters = {
  isPaymentLoading(state) {
    return state.isPaymentLoading
  },

  isPaymentValid(state) {
    return state.isPaymentValid
  },

  showBeforePaymentInterstitial(state) {
    return state.showBeforePaymentInterstitial
  },
}

const mutations = {
  SET_PAYMENT_LOADING(state, bool) {
    state.isPaymentLoading = bool
  },

  SET_PAYMENT_VALID(state, bool) {
    state.isPaymentValid = bool
  },

  SET_SHOW_BEFORE_PAYMENT_INTERSTITIAL(state, bool) {
    state.showBeforePaymentInterstitial = bool
  },
}

const actions = {
  setPaymentLoading({ commit }, bool) {
    commit('SET_PAYMENT_LOADING', bool)
  },

  setPaymentValid({ commit }, bool) {
    commit('SET_PAYMENT_VALID', bool)
  },

  setShowBeforePaymentInterstitial({ commit }, bool) {
    commit('SET_SHOW_BEFORE_PAYMENT_INTERSTITIAL', bool)
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
