
import TransparentProfileHeader from './transparentProfileHeader'
import tracker from '~/mixins/tracker'
export default {
  components: {
    TransparentProfileHeader,
  },

  mixins: [tracker],

  middleware: 'needToBeLogin',
}
