import { AxiosInstance, Method } from 'axios'

import { Request } from '../request'

export default class BaseApi {
  protected http: AxiosInstance
  protected request: Request

  constructor(request: Request) {
    this.http = request.http
    this.request = request
  }
}
