import { render, staticRenderFns } from "./NxHeader.vue?vue&type=template&id=16a35fea&"
import script from "./NxHeader.vue?vue&type=script&lang=js&"
export * from "./NxHeader.vue?vue&type=script&lang=js&"
import style0 from "./NxHeader.vue?vue&type=style&index=0&id=16a35fea&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NxLogoNextory: require('/app/components/Nx/NxLogo/NxLogoNextory.vue').default,HeaderSearch: require('/app/components/Header/HeaderSearch.vue').default,HeaderNavigation: require('/app/components/Header/HeaderNavigation.vue').default})
