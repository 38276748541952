export default {
  data() {
    return {
      // By default, page takes verticalHeight*100, so we default to 1vh before JS takes over. (avoids content-shifting)
      verticalHeight: '1vh',
    }
  },
  /* 1️⃣ add the cssVars computed in your template or page :style="verticalHeightCssVar"
   * 2️⃣ use the class .is-fullheight in your template
   */
  computed: {
    verticalHeightCssVar() {
      return {
        '--vh': this.verticalHeight,
      }
    },
  },

  mounted() {
    this.verticalHeightEvent()
    this.getVerticalHeight()
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.getVerticalHeight)
  },

  methods: {
    verticalHeightEvent() {
      window.addEventListener('resize', this.getVerticalHeight)
    },

    getVerticalHeight() {
      // resize viewport to have a correct rendering on mobile browsers
      if (typeof window !== 'undefined') {
        const screenHeight = parseInt(
          typeof window.innerHeight !== 'undefined'
            ? Math.max(
                Math.min(window.innerHeight, window.outerHeight),
                document.documentElement.clientHeight
              )
            : document.documentElement.clientHeight
        )
        this.verticalHeight = `${screenHeight * 0.01}px`
      }
    },
  },
}
