import { Plugin, Context } from '@nuxt/types'
import { Inject } from '@nuxt/types/app'
import consola from 'consola'
import { Auth } from './auth/core'

const logger = consola.withScope('Auth plugin')

const authPlugin: Plugin = function (ctx: Context, inject: Inject) {
  // Create a new Auth instance
  const $auth = new Auth(ctx)

  // Inject it to nuxt context as $auth
  inject('auth', $auth)

  return $auth.init().catch(error => {
    if (process.client) {
      // We shouldn't log an error when the auth_token expires.
      // However, NX5 doesn't let us know if it expired, or if it's a different error.
      logger.error(error)
      ctx.$sentry.captureException(error)
    }
  })
}

export default authPlugin
