import fetchWebConfig from '~/helpers/fetchWebConfig'

/*
 * This store makes it possible to access the webConfig from the CMS in the store, everywhere in the app.
 */

const state = () => ({
  config: null,
  entryUrl: null,
})

const actions = {
  async nuxtServerInit({ commit }, context) {
    const { req } = context
    const webConfig = await fetchWebConfig(context)
    commit('SET_WEBCONFIG', webConfig)

    const entryUrl = req.url // Example of a possible value: /fr/register
    commit('SET_ENTRY_URL', entryUrl)
  },
}

const mutations = {
  SET_WEBCONFIG(state, webConfig) {
    state.config = webConfig
  },

  SET_ENTRY_URL(state, entryUrl) {
    state.entryUrl = entryUrl
  },
}

export default {
  actions,
  mutations,
  state,
}
