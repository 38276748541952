const middleware = {}

middleware['auth'] = require('../middleware/auth.js')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['devDomainRedirect'] = require('../middleware/devDomainRedirect.js')
middleware['devDomainRedirect'] = middleware['devDomainRedirect'].default || middleware['devDomainRedirect']

middleware['entryUrl'] = require('../middleware/entryUrl.js')
middleware['entryUrl'] = middleware['entryUrl'].default || middleware['entryUrl']

middleware['frenchCatalogRedirect'] = require('../middleware/frenchCatalogRedirect.js')
middleware['frenchCatalogRedirect'] = middleware['frenchCatalogRedirect'].default || middleware['frenchCatalogRedirect']

middleware['needToBeLogin'] = require('../middleware/needToBeLogin.js')
middleware['needToBeLogin'] = middleware['needToBeLogin'].default || middleware['needToBeLogin']

middleware['needToBeLogout'] = require('../middleware/needToBeLogout.js')
middleware['needToBeLogout'] = middleware['needToBeLogout'].default || middleware['needToBeLogout']

middleware['orangeTunisiaRedirect'] = require('../middleware/orangeTunisiaRedirect.js')
middleware['orangeTunisiaRedirect'] = middleware['orangeTunisiaRedirect'].default || middleware['orangeTunisiaRedirect']

middleware['polygloteMarketRedirect'] = require('../middleware/polygloteMarketRedirect.js')
middleware['polygloteMarketRedirect'] = middleware['polygloteMarketRedirect'].default || middleware['polygloteMarketRedirect']

middleware['removeTrailingSlash'] = require('../middleware/removeTrailingSlash.js')
middleware['removeTrailingSlash'] = middleware['removeTrailingSlash'].default || middleware['removeTrailingSlash']

middleware['userCountryLocaleRedirect'] = require('../middleware/userCountryLocaleRedirect.js')
middleware['userCountryLocaleRedirect'] = middleware['userCountryLocaleRedirect'].default || middleware['userCountryLocaleRedirect']

export default middleware
