const state = () => ({
  currentStep: -1,
})

const mutations = {
  SET_STEP(state, stepNumber) {
    state.currentStep = stepNumber
  },
}

const getters = {
  getCurrentStep(state) {
    return state.currentStep
  },
}

export default {
  state,
  mutations,
  getters,
}
