// Redirects URLs having an extra slash to the same URL without the slash.
// Must be used with the router: trailingSlash option set to false in Nuxt config.
// We use a 301 permanent redirect.
//
// From: /hey/there/mr/president/
// To:   /hey/there/mr/president
export default function ({ redirect, route }) {
  if (route.path !== '/' && route.path.endsWith('/')) {
    const { hash, path, query } = route
    const nextPath = path.replace(/\/+$/, '') || '/'
    const nextRoute = { path: nextPath, query, hash }

    redirect(301, nextRoute)
  }
}
