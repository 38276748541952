
import { parseLocaleIso } from '~/helpers/localizationFormatter'

export default {
  props: {
    showNavigation: { type: Boolean, default: true },
  },

  data() {
    return {
      isCookieBotExpanded: false,
    }
  },

  computed: {
    contactUrl() {
      let { country } = parseLocaleIso(this.$i18n.localeProperties.iso)

      country = country.toLowerCase()

      // Belgium don't have a dedicated contact page (from the old Strapi), so for the moment we consider it as France.
      if (country === 'be') {
        country = 'fr'
      }

      return `/${country}/collab/contact/${country}/`
    },
  },

  methods: {
    currentYear() {
      return new Date().getFullYear()
    },

    clickCookie() {
      this.isCookieBotExpanded = !this.isCookieBotExpanded
      Cookiebot.show()
    },
  },
}
