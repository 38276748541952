export const staticLinks = {
  downloadAppleStore: 'https://apps.apple.com/us/app/nextory/id993578896',
  downloadGooglePlayStore:
    'https://play.google.com/store/apps/details?id=com.gtl.nextory',
  investorsUrl: 'https://investors.nextory.com/',
}

// Helper plugin to access static links in the app, as well as to make absolute URLs.
// TODO: Should be moved to helper functions, for performance reasons (no need to inject it in the app if not always used)
export function linkPlugin() {
  return {
    ...staticLinks,

    /**
     * Makes a relative URL absolute. Mainly used for callback parameters.
     *
     * Eg: /fr/register/card -> http://localhost:3300/fr/register/card
     * Eg: /fr/register/card -> https://nextory.com/fr/register/card
     */
    absolute(path) {
      let host = process.env.NEXTORY_WEB_URL

      // remove trailing slash from host
      if (host?.endsWith('/')) host = host.slice(0, -1)

      return host + path
    },
  }
}

export default (_, inject) => {
  inject('link', linkPlugin())
}
