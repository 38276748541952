
const debugLabel = '(debug)'

export default {
  props: {
    hasNavIconsLight: { type: Boolean, default: false },
  },

  data() {
    return {
      isMenuExpanded: false,
      isFullscreenOpened: true,
      markets: [
        {
          country: '🇫🇷 France',
          code: 'fr',
          lang: 'fr',
          api: 'legacy',
          languages: [
            {
              locale: 'fr',
              label: 'FR',
            },
            {
              locale: 'fr-en',
              label: 'EN',
            },
          ],
        },
        {
          country: '🇧🇪 Belgique',
          code: 'be',
          lang: 'fr',
          api: 'legacy',
          languages: [
            {
              locale: 'be-fr',
              label: 'FR',
            },
            {
              locale: 'be-en',
              label: 'EN',
            },
          ],
        },
        {
          country: '🇳🇱 Nederland',
          code: 'nl',
          lang: 'nl',
          api: 'modern',
          languages: [
            {
              locale: 'nl',
              label: 'NL',
            },
            {
              locale: 'nl-en',
              label: 'EN',
            },
            {
              locale: 'nl-lk',
              label: debugLabel,
            },
          ],
        },
        {
          country: '🇩🇪 Deutschland',
          code: 'de',
          lang: 'de',
          api: 'modern',
          languages: [
            {
              locale: 'de',
              label: 'DE',
            },
            {
              locale: 'de-en',
              label: 'EN',
            },
            {
              locale: 'de-lk',
              label: debugLabel,
            },
          ],
        },
        {
          country: '🇸🇪 Sverige',
          code: 'se',
          lang: 'sv',
          api: 'modern',
          languages: [
            {
              locale: 'se',
              label: 'SE',
            },
            {
              locale: 'se-en',
              label: 'EN',
            },
            {
              locale: 'se-lk',
              label: debugLabel,
            },
          ],
        },
        {
          country: '🇳🇴 Norge',
          code: 'no',
          lang: 'nb',
          api: 'modern',
          languages: [
            {
              locale: 'no',
              label: 'NO',
            },
            {
              locale: 'no-en',
              label: 'EN',
            },
            {
              locale: 'no-lk',
              label: debugLabel,
            },
          ],
        },
        {
          country: '🇩🇰 Danmark',
          code: 'dk',
          lang: 'da',
          api: 'modern',
          languages: [
            {
              locale: 'dk',
              label: 'DK',
            },
            {
              locale: 'dk-en',
              label: 'EN',
            },
            {
              locale: 'dk-lk',
              label: debugLabel,
            },
          ],
        },
        {
          country: '🇨🇭 Schweiz',
          code: 'ch',
          lang: 'de',
          api: 'modern',
          languages: [
            {
              locale: 'ch',
              label: 'DE',
            },
            {
              locale: 'ch-en',
              label: 'EN',
            },
            {
              locale: 'ch-lk',
              label: debugLabel,
            },
          ],
        },
        {
          country: '🇫🇮 Suomi',
          code: 'fi',
          lang: 'fi',
          api: 'modern',
          languages: [
            {
              locale: 'fi',
              label: 'FI',
            },
            {
              locale: 'fi-en',
              label: 'EN',
            },
            {
              locale: 'fi-lk',
              label: debugLabel,
            },
          ],
        },
        {
          country: '🇦🇹 Österreich',
          code: 'at',
          lang: 'de',
          api: 'modern',
          languages: [
            {
              locale: 'at',
              label: 'AT',
            },
            {
              locale: 'at-en',
              label: 'EN',
            },
            {
              locale: 'at-lk',
              label: debugLabel,
            },
          ],
        },
        {
          country: '🇪🇸 España',
          code: 'es',
          lang: 'es',
          api: 'modern',
          languages: [
            {
              locale: 'es',
              label: 'ES',
            },
            {
              locale: 'es-en',
              label: 'EN',
            },
            {
              locale: 'es-lk',
              label: debugLabel,
            },
          ],
        },
      ],
    }
  },

  computed: {
    // Current locale market is returned first depending on the country.
    sortedMarkets() {
      const currentCountry = this.$i18n.locale.slice(0, 2)
      const currentMarket = this.markets.find(
        market => market.code === currentCountry
      )
      // Remove current market from the list and sort the rest, ignoring country emojis.
      const marketName = market => market.country.replace(/\p{Emoji}/gu, '')
      const otherMarkets = this.markets
        .filter(() => {
          // If user is logged in, hide other markets
          return !this.$auth.loggedIn
        })
        .filter(market => market.code !== currentCountry)
        .sort((a, b) =>
          marketName(a).localeCompare(
            marketName(b),
            'en' /* keeping the same order on server and client sides */
          )
        )

      return [currentMarket, ...otherMarkets].filter(
        market =>
          // avoids errors when no market is found for the current locale
          typeof market !== 'undefined'
      )
    },

    canRedeemGiftcard() {
      return !(
        this.$auth.loggedIn &&
        this.$auth.user.disallowed_actions?.includes('REDEEM_GIFTCARD')
      )
    },

    canRedeemCampaign() {
      return !(
        this.$auth.loggedIn &&
        this.$auth.user.disallowed_actions?.includes('REDEEM_CAMPAIGN')
      )
    },
  },

  mounted() {
    this.observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting) {
          window.addEventListener('click', this.close, false)
          window.addEventListener('focusin', this.close, false)
          window.addEventListener('keydown', this.close, false)
        } else {
          window.removeEventListener('click', this.close, false)
          window.removeEventListener('focusin', this.close, false)
          window.removeEventListener('keydown', this.close, false)
        }
      },
      { threshold: 0 }
    )

    this.observer.observe(this.$refs.navigationDialog)
  },

  beforeDestroy() {
    // Making sure body never keeps the overflow-hidden class when switching pages
    this.$store.dispatch('setAsFullscreenComponent', false)
  },

  beforeUnmount() {
    this.observer.disconnect()
  },

  methods: {
    getChangeLocalePath(locale) {
      const destinationPage = this.switchLocalePath(locale)
      const currentPath = this.$route.path

      // if both are equal, it means the destination page doesn't exist (NuxtI18n won't tell us directly), so we return the root path
      if (destinationPage === currentPath && locale !== this.$i18n.locale) {
        return `/${locale}`
      }

      return destinationPage
    },

    close(e) {
      if (!this.$el.contains(e.target)) {
        this.hideMenu()
      }

      if (e.key === 'Escape') {
        this.hideMenu()
      }
    },

    showMenu() {
      this.isMenuExpanded = true
      this.$store.dispatch('setAsFullscreenComponent', true)
    },

    hideMenu() {
      this.isMenuExpanded = false
      this.$store.dispatch('setAsFullscreenComponent', false)
    },

    logoutUserAndClose() {
      this.$auth.logout().then(() => {
        this.hideMenu()
      })
    },

    filteredLanguages(languages) {
      if (process.env.ENVIRONMENT === 'production') {
        // debug languages are not available in production
        return languages.filter(language => language.label !== debugLabel)
      }

      return languages
    },
  },
}
