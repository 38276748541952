import { render, staticRenderFns } from "./HeaderSearch.vue?vue&type=template&id=27b9c9fa&"
import script from "./HeaderSearch.vue?vue&type=script&lang=js&"
export * from "./HeaderSearch.vue?vue&type=script&lang=js&"
import style0 from "./HeaderSearch.vue?vue&type=style&index=0&id=27b9c9fa&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NxLoader: require('/app/components/Nx/NxLoader.vue').default,CatalogBookCard: require('/app/components/Catalog/CatalogBookCard.vue').default})
