import { render, staticRenderFns } from "./user.vue?vue&type=template&id=30a88494&"
import script from "./user.vue?vue&type=script&lang=js&"
export * from "./user.vue?vue&type=script&lang=js&"
import style0 from "./user.vue?vue&type=style&index=0&id=30a88494&prod&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NxLoader: require('/app/components/Nx/NxLoader.vue').default,NxHeader: require('/app/components/Nx/NxHeader.vue').default,NxNarrowContainer: require('/app/components/Nx/NxNarrowContainer.vue').default,FlashMessages: require('/app/components/Flash/FlashMessages.vue').default,ButtonHelp: require('/app/components/ButtonHelp.vue').default})
