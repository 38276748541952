import capitalizeWords from './capitalizeWords'
import {
  CatalogAutoComplete,
  Suggestion,
} from '~/plugins/hummingbird/api-types/CatalogAutoComplete'

/**
 * Get most pertinent suggestions from the autocomplete API response
 *
 * This function will intertwine book and magazine suggestions, respectively labelled
 * 'did_you_mean' and 'did_you_mean_mag'. If less than four suggestions are generated,
 * it will add automatic suggestions ('auto_complete') to reach a maximum of four.
 * This ensures that the most relevant suggestions are presented to the user.
 *
 * @returns {Array} - An array containing the most suitable suggestions
 */
export function getMostPertinentSuggestions(
  response: CatalogAutoComplete,
  currentSearch: string,
  maxSuggestions = 4
) {
  let suggestions: string[] = []
  const pushSuggestion = (item: Suggestion) => {
    if (
      item &&
      item.text.toLowerCase() !== currentSearch.toLowerCase() // don't repeat the query
    ) {
      suggestions.push(capitalizeWords(item.text))
    }
  }

  // Interweave the book and magazine suggestions for diversification
  for (
    let i = 0;
    suggestions.length < maxSuggestions &&
    (i < (response.did_you_mean || []).length ||
      i < (response.did_you_mean_mag || []).length);
    i++
  ) {
    pushSuggestion(response.did_you_mean[i])
    pushSuggestion(response.did_you_mean_mag[i])
  }

  // Add the automatic suggestions if we have less than 4 after combining books and magazines
  for (
    let i = 0;
    suggestions.length < maxSuggestions &&
    i < (response.auto_complete || []).length;
    i++
  ) {
    pushSuggestion(response.auto_complete[i])
  }

  return suggestions
}
