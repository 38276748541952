import consola from 'consola'
import { Subscription } from '~/plugins/hummingbird/api-types/Subscription'
import { SubscriptionVariant } from '~/plugins/hummingbird/api-types/SubscriptionVariant'
import { DeviceOpportunity } from '~/plugins/hummingbird/legacy-api/types/deviceOpportunity'

const logger = consola.withScope('State - Opportunities')

const state = () => ({
  opportunities: {},
  orderedOpportunities: {},
  getIdByUuid: {},
  selectedOpportunity: undefined,
  variants: undefined,
})

const mutations = {
  SET_OPPORTUNITIES(state, opportunities) {
    state.opportunities = opportunities
  },

  SET_ORDERED_OPPORTUNITIES(state, opportunities) {
    state.orderedOpportunities = opportunities
  },

  SET_ID_BY_UUIDS(state, obj) {
    state.getIdByUuid = obj
  },

  SET_SELECTED_UUID(state, uuid) {
    state.selectedOpportunity = uuid.toString()
  },

  SET_VARIANTS(state, variants) {
    state.variants = variants
  },
}

const actions = {
  async getAvailableOpportunities(
    { commit, dispatch, state },
    promotionalCodes = {
      campaignCode: undefined,
      giftcardCode: undefined,
      channel: undefined,
    }
  ) {
    try {
      let response =
        await this.$hummingbird.deviceOpportunities.getDeviceOpportunities(
          promotionalCodes
        )

      for (const subscription of response.data.subscriptions) {
        // TODO: Remove this front-end patch once back-end has renamed aliases in Norway/Finland markets.
        const aliases = {
          BRONZE: 'SILVER',
          SILVER20: 'SILVER',
        }
        const aliasReplacement = aliases[subscription.alias]

        if (aliasReplacement) {
          subscription.alias = aliasReplacement
          for (const variant of subscription.variants) {
            if (aliasReplacement) variant.alias = aliasReplacement
          }
        }
      }

      commit('SET_OPPORTUNITIES', response.data)

      const allVariants = response.data.subscriptions.flatMap(sub => {
        sub.variants.map(variant => {
          // we'll need to know if a variant is part of a subscription with multiple variants (e.g. Unlimited 2/3/4 in some markets)
          variant.has_variant = sub.variants.length > 1
        })

        return sub.variants
      })

      commit('SET_VARIANTS', allVariants)

      let bestOffer = await dispatch(
        'getSubscriptionIdFromAliasName',
        this.state.webConfig.config.preselectedSubscription
      )

      if (!bestOffer) {
        // If best offer is not available in the current context (COOP partner campaign, limited campaign, ...), we select the last one as a fallback
        bestOffer = allVariants[allVariants.length - 1].subscription_id
      }

      // If the selected opportunity is not available anymore, we select the best offer
      const selectedOpportunityExists =
        state.selectedOpportunity &&
        allVariants.some(
          variant =>
            variant.subscription_id.toString() === state.selectedOpportunity
        )

      if (!selectedOpportunityExists && bestOffer) {
        commit('SET_SELECTED_UUID', bestOffer)
      }
    } catch (err) {
      logger.error(err)
    }
    return state.opportunities
  },

  async getOpportunityByUuid({ dispatch, state }, { channel, code, uuid }) {
    // Make sure we have a string
    uuid = uuid.toString()

    if (Object.keys(state.opportunities).length === 0) {
      await dispatch('getAvailableOpportunities', {
        campaignCode: code,
        channel,
      })
    }

    if (!uuid) uuid = state.selectedOpportunity

    /** @type {Subscription} */
    const subscription = state.opportunities.subscriptions.find(s =>
      s.variants.some(v => v.subscription_id.toString() === uuid)
    )

    /** @type {SubscriptionVariant} */
    const variant = subscription?.variants.find(
      v => v.subscription_id.toString() === uuid
    )

    if (!variant) {
      throw new Error('This Opportunity UUID Does Not Exist!')
    }

    /**
     * FIXME: We're recreating a DeviceOpportunity to make it look like the legacy API here.
     *        This is a temporary fix until the /card page is redone with the new API,
     *        it will need to be removed.
     *
     * @type {DeviceOpportunity}
     */
    const legacyOffer = {
      background_primary_color: null,
      background_secondary_color: null,
      features: [],
      gateway: {
        payment_provider: 'ADYEN',
        offer: {
          number_of_days_before_first_payment: subscription.trial_days,
          currency: subscription.currency,
          amount: variant.amount,
          id: variant.subscription_id,
        },
        uuid: variant.subscription_id,
      },
      id: variant.subscription_id,
      name: variant.plan_name,
      selection: null,
      text_color: '',
      alias: variant.alias,
    }

    return legacyOffer
  },

  async getSubscriptionIdFromAliasName({ state }, alias) {
    const variant = state.variants.find(variant => variant.alias === alias)
    return variant?.subscription_id
  },
}

const getters = {
  getOpportunities(state) {
    return state.opportunities
  },

  getSelectedUuid(state) {
    return state.selectedOpportunity
  },

  getOpportunitiesVariants(state) {
    return state.variants
  },

  selectedSubscriptionHasVariants(state) {
    return !!state.variants?.find(
      variant =>
        variant.subscription_id.toString() === state.selectedOpportunity &&
        variant.has_variant
    )
  },
}

export default {
  state,
  mutations,
  actions,
  getters,
}
