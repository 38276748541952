import store from '../steps'

const state = () => ({
  ...store.state(),
})

const getters = {
  ...store.getters,
}

const mutations = {
  ...store.mutations,
}

export default {
  state,
  getters,
  mutations,
}
