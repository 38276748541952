
export default {
  props: {
    theme: {
      type: String,
      default: 'dark',
      validator: v => ['dark', 'light'].includes(v),
    },
  },
}
