/**
 * This middleware redirects logged-in users
 * to their corresponding country/market based
 * on their country and preferred language.
 *
 * Logged-out users are allowed to see all markets.
 */
export default function ({ $auth, app: { i18n }, redirect, route }) {
  if (!$auth.loggedIn || route.fullPath === '/') {
    // Not logged in, allowed to see all markets
    return
  }

  const currentCountry = i18n.localeProperties.iso.split('-')[1].toLowerCase()
  if (
    process.env.NODE_ENV !== 'production' &&
    route.fullPath.startsWith(`/${currentCountry}-lk`)
  ) {
    // Special case for locale debugging (/nl-lk, etc)
    return
  }

  const preferredLocale = $auth.getPreferredUserLocale()

  if (i18n.localeProperties.code !== preferredLocale) {
    const to = route.fullPath.replace(`/${i18n.locale}`, `/${preferredLocale}`)

    if (process.server) {
      redirect(to)
    } else {
      // On client, we can't handle the locale change there (i18n enters an infinite loop)
      // So, we let $auth->redirect do it for us.
    }
  }
}
