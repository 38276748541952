import locale77427e78 from '../../i18n/locales/en.js'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: false,
}
export const options = {
  vueI18n: {"fallbackLocale":"se-en","silentTranslationWarn":false,"dateTimeFormats":{"en":{"short":{"year":"numeric","month":"short","day":"numeric"},"long":{"year":"numeric","month":"long","day":"numeric","weekday":"long","hour":"numeric","minute":"numeric"}}}},
  vueI18nLoader: false,
  locales: [{"code":"se","file":"sv.js","iso":"sv-SE","name":"Swedish"},{"code":"se-en","file":"en.js","iso":"en-SE","name":"English (Sweden)"},{"code":"fr","file":"fr_FR.js","iso":"fr-FR","name":"Français"},{"code":"fr-en","file":"en_FR.js","baseFile":"en.js","iso":"en-FR","name":"English"},{"code":"be-fr","file":"fr_FR.js","iso":"fr-BE","name":"Français (Belge)"},{"code":"be-en","file":"en_BE.js","baseFile":"en.js","iso":"en-BE","name":"English (Belgium)"},{"code":"nl","file":"nl.js","iso":"nl-NL","name":"Dutch"},{"code":"nl-en","file":"en.js","iso":"en-NL","name":"English (Netherlands)"},{"code":"no","file":"no.js","iso":"nb-NO","name":"Norwegian"},{"code":"no-en","file":"en.js","iso":"en-NO","name":"English (Norway)"},{"code":"es","file":"es_ES.js","iso":"es-ES","name":"Spanish"},{"code":"es-en","file":"en.js","iso":"en-ES","name":"English (Spain)"},{"code":"dk","file":"da.js","iso":"da-DK","name":"Danish"},{"code":"dk-en","file":"en.js","iso":"en-DK","name":"English (Denmark)"},{"code":"fi","file":"fi.js","iso":"fi-FI","name":"Finnish"},{"code":"fi-en","file":"en.js","iso":"en-FI","name":"English (Finland)"},{"code":"ch","file":"de_CH.js","iso":"de-CH","name":"Switzerland"},{"code":"ch-en","file":"en.js","iso":"en-CH","name":"English (Switzerland)"},{"code":"at","file":"de_AT.js","iso":"de-AT","name":"Austria"},{"code":"at-en","file":"en.js","iso":"en-AT","name":"English (Austria)"},{"code":"de","file":"de_DE.js","iso":"de-DE","name":"German"},{"code":"de-en","file":"en.js","iso":"en-DE","name":"English (Germany)"}],
  defaultLocale: "se-en",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix",
  lazy: true,
  langDir: "/app/i18n/locales/",
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":false,"cookieAge":365,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"fallbackLocale":"se-en","redirectOn":"no prefix","useCookie":false},
  differentDomains: false,
  baseUrl: "https://nextory.com",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"se","file":"sv.js","iso":"sv-SE","name":"Swedish"},{"code":"se-en","file":"en.js","iso":"en-SE","name":"English (Sweden)"},{"code":"fr","file":"fr_FR.js","iso":"fr-FR","name":"Français"},{"code":"fr-en","file":"en_FR.js","baseFile":"en.js","iso":"en-FR","name":"English"},{"code":"be-fr","file":"fr_FR.js","iso":"fr-BE","name":"Français (Belge)"},{"code":"be-en","file":"en_BE.js","baseFile":"en.js","iso":"en-BE","name":"English (Belgium)"},{"code":"nl","file":"nl.js","iso":"nl-NL","name":"Dutch"},{"code":"nl-en","file":"en.js","iso":"en-NL","name":"English (Netherlands)"},{"code":"no","file":"no.js","iso":"nb-NO","name":"Norwegian"},{"code":"no-en","file":"en.js","iso":"en-NO","name":"English (Norway)"},{"code":"es","file":"es_ES.js","iso":"es-ES","name":"Spanish"},{"code":"es-en","file":"en.js","iso":"en-ES","name":"English (Spain)"},{"code":"dk","file":"da.js","iso":"da-DK","name":"Danish"},{"code":"dk-en","file":"en.js","iso":"en-DK","name":"English (Denmark)"},{"code":"fi","file":"fi.js","iso":"fi-FI","name":"Finnish"},{"code":"fi-en","file":"en.js","iso":"en-FI","name":"English (Finland)"},{"code":"ch","file":"de_CH.js","iso":"de-CH","name":"Switzerland"},{"code":"ch-en","file":"en.js","iso":"en-CH","name":"English (Switzerland)"},{"code":"at","file":"de_AT.js","iso":"de-AT","name":"Austria"},{"code":"at-en","file":"en.js","iso":"en-AT","name":"English (Austria)"},{"code":"de","file":"de_DE.js","iso":"de-DE","name":"German"},{"code":"de-en","file":"en.js","iso":"en-DE","name":"English (Germany)"}],
  localeCodes: ["se","se-en","fr","fr-en","be-fr","be-en","nl","nl-en","no","no-en","es","es-en","dk","dk-en","fi","fi-en","ch","ch-en","at","at-en","de","de-en"],
  additionalMessages: [],
}
export const localeFiles = {
  0: "{",
  1: "\"",
  2: "s",
  3: "v",
  4: ".",
  5: "j",
  6: "s",
  7: "\"",
  8: ":",
  9: "\"",
  10: ".",
  11: ".",
  12: "/",
  13: "i",
  14: "1",
  15: "8",
  16: "n",
  17: "/",
  18: "l",
  19: "o",
  20: "c",
  21: "a",
  22: "l",
  23: "e",
  24: "s",
  25: "/",
  26: "s",
  27: "v",
  28: ".",
  29: "j",
  30: "s",
  31: "\"",
  32: ",",
  33: "\"",
  34: "e",
  35: "n",
  36: ".",
  37: "j",
  38: "s",
  39: "\"",
  40: ":",
  41: "\"",
  42: ".",
  43: ".",
  44: "/",
  45: "i",
  46: "1",
  47: "8",
  48: "n",
  49: "/",
  50: "l",
  51: "o",
  52: "c",
  53: "a",
  54: "l",
  55: "e",
  56: "s",
  57: "/",
  58: "e",
  59: "n",
  60: ".",
  61: "j",
  62: "s",
  63: "\"",
  64: ",",
  65: "\"",
  66: "f",
  67: "r",
  68: "_",
  69: "F",
  70: "R",
  71: ".",
  72: "j",
  73: "s",
  74: "\"",
  75: ":",
  76: "\"",
  77: ".",
  78: ".",
  79: "/",
  80: "i",
  81: "1",
  82: "8",
  83: "n",
  84: "/",
  85: "l",
  86: "o",
  87: "c",
  88: "a",
  89: "l",
  90: "e",
  91: "s",
  92: "/",
  93: "f",
  94: "r",
  95: "_",
  96: "F",
  97: "R",
  98: ".",
  99: "j",
  100: "s",
  101: "\"",
  102: ",",
  103: "\"",
  104: "e",
  105: "n",
  106: "_",
  107: "F",
  108: "R",
  109: ".",
  110: "j",
  111: "s",
  112: "\"",
  113: ":",
  114: "\"",
  115: ".",
  116: ".",
  117: "/",
  118: "i",
  119: "1",
  120: "8",
  121: "n",
  122: "/",
  123: "l",
  124: "o",
  125: "c",
  126: "a",
  127: "l",
  128: "e",
  129: "s",
  130: "/",
  131: "e",
  132: "n",
  133: "_",
  134: "F",
  135: "R",
  136: ".",
  137: "j",
  138: "s",
  139: "\"",
  140: ",",
  141: "\"",
  142: "e",
  143: "n",
  144: "_",
  145: "B",
  146: "E",
  147: ".",
  148: "j",
  149: "s",
  150: "\"",
  151: ":",
  152: "\"",
  153: ".",
  154: ".",
  155: "/",
  156: "i",
  157: "1",
  158: "8",
  159: "n",
  160: "/",
  161: "l",
  162: "o",
  163: "c",
  164: "a",
  165: "l",
  166: "e",
  167: "s",
  168: "/",
  169: "e",
  170: "n",
  171: "_",
  172: "B",
  173: "E",
  174: ".",
  175: "j",
  176: "s",
  177: "\"",
  178: ",",
  179: "\"",
  180: "n",
  181: "l",
  182: ".",
  183: "j",
  184: "s",
  185: "\"",
  186: ":",
  187: "\"",
  188: ".",
  189: ".",
  190: "/",
  191: "i",
  192: "1",
  193: "8",
  194: "n",
  195: "/",
  196: "l",
  197: "o",
  198: "c",
  199: "a",
  200: "l",
  201: "e",
  202: "s",
  203: "/",
  204: "n",
  205: "l",
  206: ".",
  207: "j",
  208: "s",
  209: "\"",
  210: ",",
  211: "\"",
  212: "n",
  213: "o",
  214: ".",
  215: "j",
  216: "s",
  217: "\"",
  218: ":",
  219: "\"",
  220: ".",
  221: ".",
  222: "/",
  223: "i",
  224: "1",
  225: "8",
  226: "n",
  227: "/",
  228: "l",
  229: "o",
  230: "c",
  231: "a",
  232: "l",
  233: "e",
  234: "s",
  235: "/",
  236: "n",
  237: "o",
  238: ".",
  239: "j",
  240: "s",
  241: "\"",
  242: ",",
  243: "\"",
  244: "e",
  245: "s",
  246: "_",
  247: "E",
  248: "S",
  249: ".",
  250: "j",
  251: "s",
  252: "\"",
  253: ":",
  254: "\"",
  255: ".",
  256: ".",
  257: "/",
  258: "i",
  259: "1",
  260: "8",
  261: "n",
  262: "/",
  263: "l",
  264: "o",
  265: "c",
  266: "a",
  267: "l",
  268: "e",
  269: "s",
  270: "/",
  271: "e",
  272: "s",
  273: "_",
  274: "E",
  275: "S",
  276: ".",
  277: "j",
  278: "s",
  279: "\"",
  280: ",",
  281: "\"",
  282: "d",
  283: "a",
  284: ".",
  285: "j",
  286: "s",
  287: "\"",
  288: ":",
  289: "\"",
  290: ".",
  291: ".",
  292: "/",
  293: "i",
  294: "1",
  295: "8",
  296: "n",
  297: "/",
  298: "l",
  299: "o",
  300: "c",
  301: "a",
  302: "l",
  303: "e",
  304: "s",
  305: "/",
  306: "d",
  307: "a",
  308: ".",
  309: "j",
  310: "s",
  311: "\"",
  312: ",",
  313: "\"",
  314: "f",
  315: "i",
  316: ".",
  317: "j",
  318: "s",
  319: "\"",
  320: ":",
  321: "\"",
  322: ".",
  323: ".",
  324: "/",
  325: "i",
  326: "1",
  327: "8",
  328: "n",
  329: "/",
  330: "l",
  331: "o",
  332: "c",
  333: "a",
  334: "l",
  335: "e",
  336: "s",
  337: "/",
  338: "f",
  339: "i",
  340: ".",
  341: "j",
  342: "s",
  343: "\"",
  344: ",",
  345: "\"",
  346: "d",
  347: "e",
  348: "_",
  349: "C",
  350: "H",
  351: ".",
  352: "j",
  353: "s",
  354: "\"",
  355: ":",
  356: "\"",
  357: ".",
  358: ".",
  359: "/",
  360: "i",
  361: "1",
  362: "8",
  363: "n",
  364: "/",
  365: "l",
  366: "o",
  367: "c",
  368: "a",
  369: "l",
  370: "e",
  371: "s",
  372: "/",
  373: "d",
  374: "e",
  375: "_",
  376: "C",
  377: "H",
  378: ".",
  379: "j",
  380: "s",
  381: "\"",
  382: ",",
  383: "\"",
  384: "d",
  385: "e",
  386: "_",
  387: "A",
  388: "T",
  389: ".",
  390: "j",
  391: "s",
  392: "\"",
  393: ":",
  394: "\"",
  395: ".",
  396: ".",
  397: "/",
  398: "i",
  399: "1",
  400: "8",
  401: "n",
  402: "/",
  403: "l",
  404: "o",
  405: "c",
  406: "a",
  407: "l",
  408: "e",
  409: "s",
  410: "/",
  411: "d",
  412: "e",
  413: "_",
  414: "A",
  415: "T",
  416: ".",
  417: "j",
  418: "s",
  419: "\"",
  420: ",",
  421: "\"",
  422: "d",
  423: "e",
  424: "_",
  425: "D",
  426: "E",
  427: ".",
  428: "j",
  429: "s",
  430: "\"",
  431: ":",
  432: "\"",
  433: ".",
  434: ".",
  435: "/",
  436: "i",
  437: "1",
  438: "8",
  439: "n",
  440: "/",
  441: "l",
  442: "o",
  443: "c",
  444: "a",
  445: "l",
  446: "e",
  447: "s",
  448: "/",
  449: "d",
  450: "e",
  451: "_",
  452: "D",
  453: "E",
  454: ".",
  455: "j",
  456: "s",
  457: "\"",
  458: "}",
}

export const localeMessages = {
  'en.js': () => Promise.resolve(locale77427e78),
  'sv.js': () => import('../../i18n/locales/sv.js' /* webpackChunkName: "lang-sv.js" */),
  'fr_FR.js': () => import('../../i18n/locales/fr_FR.js' /* webpackChunkName: "lang-fr_FR.js" */),
  'en_FR.js': () => import('../../i18n/locales/en_FR.js' /* webpackChunkName: "lang-en_FR.js" */),
  'en_BE.js': () => import('../../i18n/locales/en_BE.js' /* webpackChunkName: "lang-en_BE.js" */),
  'nl.js': () => import('../../i18n/locales/nl.js' /* webpackChunkName: "lang-nl.js" */),
  'no.js': () => import('../../i18n/locales/no.js' /* webpackChunkName: "lang-no.js" */),
  'es_ES.js': () => import('../../i18n/locales/es_ES.js' /* webpackChunkName: "lang-es_ES.js" */),
  'da.js': () => import('../../i18n/locales/da.js' /* webpackChunkName: "lang-da.js" */),
  'fi.js': () => import('../../i18n/locales/fi.js' /* webpackChunkName: "lang-fi.js" */),
  'de_CH.js': () => import('../../i18n/locales/de_CH.js' /* webpackChunkName: "lang-de_CH.js" */),
  'de_AT.js': () => import('../../i18n/locales/de_AT.js' /* webpackChunkName: "lang-de_AT.js" */),
  'de_DE.js': () => import('../../i18n/locales/de_DE.js' /* webpackChunkName: "lang-de_DE.js" */),
}
