/**
 * Some markets, like Belgium, don't have a default language.
 * So we need to redirect them to a specific language, as a fallback.
 */
export default function ({ redirect, route }) {
  const mappings = {
    be: 'be-fr',
  }
  const currentMarket = route.fullPath.split('/')[1]

  if (mappings[currentMarket]) {
    const redirectUrl = route.fullPath.replace(
      `/${currentMarket}`,
      `/${mappings[currentMarket]}`
    )
    redirect(redirectUrl)
  }
}
